import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import logo from '../../../assets/img/logo.svg';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={`${classes.wrap} mt font-17`}>
      <div className="container">
        <div className={classes.footer}>
          <img className={classes.logo} src={logo} alt='Supra' />
          <div className={classes.content}>
            <div className={classes.col}>
              <a href="tel:+380 (97) 782 69 04">
                +380 (97) 782 69 04
              </a>
              <a href="mailto:support@supra.consulting">
                support@supra.consulting
              </a>
            </div>
            <div className={classes.col}>
              <a
                className={classes.doc}
                href="/docs/OFFER_CONTRACT.pdf"
                target="_blank"
              >
                {t('footer.doc.terms')}
              </a>
              <a
                className={classes.doc}
                href="/docs/PRIVACY_POLICY.pdf"
                target="_blank"
              >
                {t('footer.doc.policy')}
              </a>
            </div>
            <div className={classes.col}>
              <p className={classes.rights}>
                {t('footer.rights')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import NotFound from '../pages/NotFound';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import AiPage from '../pages/AiPage';
import ItPage from '../pages/ItPage';
import CryptoPage from '../pages/CryptoPage';
import MarketingPage from '../pages/MarketingPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/:lang/', element: HomePage },

    { path: '/:lang/services/ai', element: AiPage },
    { path: '/:lang/services/it', element: ItPage },
    { path: '/:lang/services/crypto', element: CryptoPage },
    { path: '/:lang/services/marketing', element: MarketingPage },

    { path: '/:lang/about', element: AboutPage },
    { path: '*', element: NotFound },
];

export const headerRoutes = [
    { 
        text: 'header.services',
        routes: [
            { path: '/services/ai', text: 'header.ai' },
            { path: '/services/it', text: 'header.it' },
            { path: '/services/crypto', text: 'header.crypto' },
            { path: '/services/marketing', text: 'header.marketing' },
        ],
    },
    { path: '/about', text: 'header.about' },
];

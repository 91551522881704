import React, { useState, useEffect } from "react";
import classes from './JoinUs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/JoinUs/image.webp';
import item_01 from '../../../assets/img/HomePage/JoinUs/item_01.svg';
import item_02 from '../../../assets/img/HomePage/JoinUs/item_02.svg';
import item_03 from '../../../assets/img/HomePage/JoinUs/item_03.webp';
import Form from '../../GlobalComponents/Form/Form';

const JoinUs = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.joinUs}>
                <h2 className={`${classes.title} font-40-800`}>
                    <Trans>
                        {t('home.join_us.title')}
                    </Trans>
                </h2>
                <p className={`${classes.text} font-17`}>
                    {t('home.join_us.text')}
                </p>
                <div className={classes.form}>
                    <Form />
                </div>
                {windowWidth > 992 &&
                    <>
                        <img className={`${classes.item} ${classes.item_01}`} src={item_01} alt='' />
                        <img className={`${classes.item} ${classes.item_02}`} src={item_02} alt='' />
                        <img className={`${classes.item} ${classes.item_03}`} src={item_03} alt='' />
                    </>
                }
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default JoinUs;

import React, { useState, useEffect } from "react";
import classes from './Wish.module.scss';
import circle_big_ua from '../../../assets/img/HomePage/Wish/circle_big_ua.svg';
import circle_small_ua from '../../../assets/img/HomePage/Wish/circle_small_ua.svg';
import item_01 from '../../../assets/img/HomePage/Wish/item_01.svg';
import item_02 from '../../../assets/img/HomePage/Wish/item_02.png';
import item_03 from '../../../assets/img/HomePage/Wish/item_03.svg';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";
import { useTranslation } from "react-i18next";

const Wish = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wish}>
                <div className={classes.content}>
                    <h2 className={classes.title}>
                        <span className="font-40-800">
                            {t('home.wish.title')}
                        </span>
                        <span className="font-17">
                            {t('home.wish.text')}
                        </span>
                    </h2>
                    <div className={classes.bottom}>
                        <span className="font-17">
                            {t('home.wish.subtitle')}
                        </span>
                        <span className="font-40-800">
                            {t('home.wish.subtext')}
                        </span>
                    </div>
                    {windowWidth <= 1220 &&
                        <div className={classes.btn}>
                            <ArrowButton circleRed />
                        </div>
                    }
                </div>
                <div className={classes.circles}>
                    <div className={classes.big}>
                        <img src={circle_big_ua} alt='' />
                    </div>
                    <div className={classes.small}>
                        <img src={circle_small_ua} alt='' />
                    </div>
                    {windowWidth > 1220 &&
                        <div className={classes.btn}>
                            <ArrowButton circleRed />
                        </div>
                    }
                </div>
                <div className={`${classes.item} ${classes.item_01}`}>
                    <img src={item_01} alt='' />
                </div>
                <div className={`${classes.item} ${classes.item_02}`}>
                    <img src={item_02} alt='' />
                </div>
                <div className={`${classes.item} ${classes.item_03}`}>
                    <img src={item_03} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Wish;

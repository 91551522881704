import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/banner.webp';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={`${classes.title} font-100-84-64-30`}>
                        {t('about.banner.title')}
                    </h1>
                    <h3 className={`${classes.subtitle} font-30-26-20-16`}>
                        {t('about.banner.subtitle')}
                    </h3>
                    <p className={`${classes.text} font-17`}>
                        {t('about.banner.text')}
                    </p>
                    <div className={classes.btn}>
                        <ArrowButton circleRed />
                    </div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import classes from './WhyChoose.module.scss';
import { Trans, useTranslation } from "react-i18next";

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.whyChoose}>
                <h2 className={classes.title}>
                    <Trans>
                        {t('crypto.why_choose.title')}
                    </Trans>
                </h2>
                <ul className={`${classes.list} font-17`}>
                    <div className={classes.col}>
                        <li>
                            {t('crypto.why_choose.li_01')}
                        </li>
                        <li>
                            {t('crypto.why_choose.li_02')}
                        </li>
                    </div>
                    <div className={classes.col}>
                        <li>
                            {t('crypto.why_choose.li_03')}
                        </li>
                        <li>
                            {t('crypto.why_choose.li_04')}
                        </li>
                    </div>
                </ul>
            </div>
        </section>
    );
};

export default WhyChoose;

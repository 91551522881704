import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/ItPage/banner.webp';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={classes.title}>
                        <Trans>
                            {t('it.banner.title')}
                        </Trans>
                    </h1>
                    <p className={`${classes.text} font-17`}>
                        {t('it.banner.text')}
                    </p>
                    <div className={classes.btn}>
                        <ArrowButton circleRed />
                    </div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React, { useState } from "react";
import classes from './ArrowButton.module.scss';
import { useTranslation } from "react-i18next";

const ArrowButton = ({ children, circleRed, colorBlack, event }) => {
    const { t } = useTranslation();
    const [circleStyle, setCircleStyle] = useState({ right: '0' });
    
    const handleMouseMove = (e) => {
        const circleWidth = document.getElementsByClassName(`${classes.circle}`)[0].clientWidth;
        const rect = e.currentTarget.getBoundingClientRect();
        const x = rect.right - e.clientX;
        setCircleStyle({ right: `${x - circleWidth / 2}px`, transform: 'scale(1.2)' });
    };

    const handleMouseLeave = () =>
        setCircleStyle({ right: '0px', transform: 'scale(1)' });

    if (event) {
        return (
            <div 
                className={`${classes.button} ${colorBlack ? classes.black : ''} no-select`} 
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={event ? event : null}
            >
                <p className={classes.text}>
                    {children ?? t('btn.consultation')}
                </p>
                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="251" height="16" viewBox="0 0 251 16" fill="none">
                    <path d="M250.707 8.70713C251.098 8.3166 251.098 7.68344 250.707 7.29292L244.343 0.928954C243.953 0.538429 243.319 0.538429 242.929 0.928953C242.538 1.31948 242.538 1.95264 242.929 2.34317L248.586 8.00002L242.929 13.6569C242.538 14.0474 242.538 14.6806 242.929 15.0711C243.319 15.4616 243.953 15.4616 244.343 15.0711L250.707 8.70713ZM-8.74228e-08 9L250 9.00002L250 7.00002L8.74228e-08 7L-8.74228e-08 9Z" fill="#FDECEF" />
                </svg>
                <div 
                    className={`${classes.circle} ${circleRed ? classes.red : ''}`} 
                    style={circleStyle}
                ></div>
            </div>
        );
    };

    return (
        <a 
            className={`${classes.button} ${colorBlack ? classes.black : ''} no-select`} 
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            href={`${process.env.REACT_APP_CABINET_URL}`}
        >
            <p className={classes.text}>
                {children ?? t('btn.consultation')}
            </p>
            <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="251" height="16" viewBox="0 0 251 16" fill="none">
                <path d="M250.707 8.70713C251.098 8.3166 251.098 7.68344 250.707 7.29292L244.343 0.928954C243.953 0.538429 243.319 0.538429 242.929 0.928953C242.538 1.31948 242.538 1.95264 242.929 2.34317L248.586 8.00002L242.929 13.6569C242.538 14.0474 242.538 14.6806 242.929 15.0711C243.319 15.4616 243.953 15.4616 244.343 15.0711L250.707 8.70713ZM-8.74228e-08 9L250 9.00002L250 7.00002L8.74228e-08 7L-8.74228e-08 9Z" fill="#FDECEF" />
            </svg>
            <div 
                className={`${classes.circle} ${circleRed ? classes.red : ''}`} 
                style={circleStyle}
            ></div>
        </a>
    );
};

export default ArrowButton;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/GlobalComponents/Header/Header';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Banner from '../components/ItPage/Banner/Banner';
import System from '../components/ItPage/System/System';
import WhereNeed from '../components/ItPage/WhereNeed/WhereNeed';
import Result from '../components/ItPage/Result/Result';
import Advantages from '../components/ItPage/Advantages/Advantages';

const ItPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Supra</title>
        <meta name="title" content="" />
        <meta property="og:title" content="" />
        <meta property="twitter:title" content="" />

        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="twitter:description" content="" />
      </Helmet>
      <Header />
      <Banner />
      <System />
      <WhereNeed />
      <Result />
      <Advantages />
      <Footer />
    </div>
  );
};

export default ItPage;

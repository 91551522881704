import React from "react";
import classes from './Consultations.module.scss';
import { Trans, useTranslation } from "react-i18next";
import checkbox from '../../../assets/img/AiPage/WeProvide/checkbox.svg';
import image from '../../../assets/img/CryptoPage/consultations.webp';

const Consultations = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.consultations}>
                <h2 className={classes.title}>
                    <Trans>
                        {t('crypto.consultations.title')}
                    </Trans>
                </h2>
                <ul className={`${classes.list} font-17`}>
                    {Array.from({ length: 8 }).map((_, index) =>
                        <li key={index} className={`${classes.item} font-17`}>
                            <img className={classes.checkbox} src={checkbox} alt='' />
                            {t(`crypto.consultations.li_0${index+1}`)}
                        </li>
                    )}
                </ul>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Consultations;

import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/MarketingPage/banner.webp';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <h1 className={classes.title}>
                    <Trans>
                        {t('marketing.banner.title')}
                    </Trans>
                </h1>
                <img className={classes.image} src={image} alt='' />
                <div className={`${classes.text} font-17`}>
                    <p>
                        {t('marketing.banner.text_01')}
                    </p>
                    <p>
                        {t('marketing.banner.text_02')}
                    </p>
                    <p>
                        {t('marketing.banner.text_03')}
                    </p>
                </div>
                <div className={classes.btn}>
                    <ArrowButton circleRed />
                </div>
            </div>
        </section>
    );
};

export default Banner;

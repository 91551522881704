import React from "react";
import classes from './WhyChoose.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/why_choose_head.webp';

const list = [
    'home.why_choose.li_01',
    'home.why_choose.li_02',
    'home.why_choose.li_03',
    'home.why_choose.li_04',
    'home.why_choose.li_05',
    'home.why_choose.li_06',
    'home.why_choose.li_07',
    'home.why_choose.li_08',
];

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.whyChoose}>
                <h2 className={classes.title}>
                    <span className={classes.sub_01}>
                        <span className="font-100">
                            {t('home.why_choose.title_01')}
                        </span>&nbsp;&nbsp;
                        <span className="font-40-800">
                            {t('home.why_choose.title_02')}
                        </span>
                    </span>
                    <span className={`${classes.sub_02} font-162`}>
                        Supra
                    </span>
                </h2>
                <ul className={`${classes.list} font-17`}>
                    {list.map((li, index) =>
                        <li key={index} className={classes.item}>
                            {t(li)}
                        </li>
                    )}
                </ul>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default WhyChoose;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation, Trans } from "react-i18next";
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";
import image from '../../../assets/img/CryptoPage/banner.webp';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
        <div className={classes.banner}>
            <div className={classes.content}>
                <h1 className={classes.title}>
                    <Trans>
                        {t('crypto.banner.title')}
                    </Trans>
                </h1>
                <p className={`${classes.text} font-17`}>
                    {t('crypto.banner.text')}
                </p>
                <div className={classes.btn}>
                    <ArrowButton circleRed />
                </div>
            </div>
            <div className={classes.image}>
                <img src={image} alt='' />
            </div>
        </div>
        </section>
    );
};

export default Banner;

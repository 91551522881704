import React, { useEffect, useState } from "react";
import classes from './Form.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Input from "../Input/Input";
import Phone from '../Input/Phone';
import { validateString, validatePhone, validateAgree } from "./validation";
import axios from 'axios';
import successIcon from '../../../assets/img/Form/success.svg';
import errorIcon from '../../../assets/img/Form/error.svg';
import processIcon from '../../../assets/img/Form/process.svg';
import ArrowButton from "../ArrowButton/ArrowButton";

const apiUrl = process.env.REACT_APP_FORM_API;

const Form = () => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState(false);
    const [process, setProcess] = useState(false);
    const [error, setError] = useState(false);

    const [name, setName] = useState('');
    const [telegram, setTelegram] = useState('');
    const [termsAgree, setTermsAgree] = useState(false);
    const [phone, setPhone] = useState('');

    const [nameValid, setNameValid] = useState(true);
    const [telegramValid, setTelegramValid] = useState(true);
    const [termsAgreeValid, setTermsAgreeValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);

    const setDataDefaultState = () => {
        setName('');
        setTelegram('');
        setPhone('');
        setTermsAgree(false);
        setNameValid(true);
        setTelegramValid(true);
        setPhoneValid(true);
        setTermsAgreeValid(true);
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];

        validArr.push(validateString(name, setNameValid));
        validArr.push(validatePhone(phone, setPhoneValid));
        validArr.push(validateString(telegram, setTelegramValid));
        validArr.push(validateAgree(termsAgree, setTermsAgreeValid));

        validArr.forEach((item) => {
            if (!item) {
                valid = false;
            }
        });

        return valid;
    };

    const sendMessage = async () => {

        setProcess(true);

        const messageData = {
            name,
            phone,
            telegram,
        };

        setDataDefaultState();

        try {
            const data = await axios.post(apiUrl, messageData);

            setProcess(false);

            if (data.status === 200) {
                setSuccess(true);
            } else {
                setError(true);
            }

        } catch (error) {
            setProcess(false);
            setError(true);
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    useEffect(() => {
        !nameValid && validateString(name, setNameValid);
        // eslint-disable-next-line
    }, [name]);
    useEffect(() => {
        !phoneValid && validatePhone(phone, setPhoneValid);
        // eslint-disable-next-line
    }, [phone]);
    useEffect(() => {
        !telegramValid && validateString(telegram, setTelegramValid);
        // eslint-disable-next-line
    }, [telegram]);
    useEffect(() => {
        !termsAgreeValid && validateAgree(termsAgree, setTermsAgreeValid);
        // eslint-disable-next-line
    }, [termsAgree]);

    return (
        <form className={classes.formWrap}>
            <div className={`${classes.status} ${classes.statusError} ${error ? classes.active : ''}`}>
                <img className={classes.statusIcon} src={errorIcon} alt='' />
                <p className={`${classes.statusText} font-17`}>
                    <Trans>
                        {t('home.form.status.error')}
                    </Trans>
                </p>
            </div>
            <div className={`${classes.status} ${classes.statusSuccess} ${success ? classes.active : ''}`}>
                <img className={classes.statusIcon} src={successIcon} alt='' />
                <p className={`${classes.statusText} font-17`}>
                    <Trans>
                        {t('home.form.status.success')}
                    </Trans>
                </p>
            </div>
            <div className={`${classes.status} ${classes.statusProcess} ${process ? classes.active : ''}`}>
                <img className={classes.statusIcon} src={processIcon} alt='' />
                <p className={`${classes.statusText} font-17`}>
                    {t('home.form.status.process')}
                </p>
            </div>
            <div className={`
                ${classes.form}
                ${(process || success || error) ? classes.blur : ''}
            `}>
                <div className={classes.inputs}>
                    <Input
                        value={name}
                        setValue={setName}
                        valid={nameValid}
                        type="text"
                        placeholder={t('home.form.name')}
                    />
                    <Phone
                        value={phone}
                        setValue={setPhone}
                        valid={phoneValid}
                        placeholder={t('home.form.phone')}
                    />
                    <Input
                        value={telegram}
                        setValue={setTelegram}
                        valid={telegramValid}
                        type="text"
                        placeholder={t('home.form.telegram')}
                    />
                    <div className={`${classes.check} ${!termsAgreeValid && classes.incorrect}`}>
                        <div
                            className={`${classes.checkBox} ${termsAgree && classes.checkBoxActive}`}
                            onClick={() => setTermsAgree(value => !value)}
                        >
                        </div>
                        <p className={classes.checkText}>
                            <Trans components={{ a: <a href="/docs/OFFER_CONTRACT.pdf" target="_blank"></a> }}>
                                {t('home.form.terms_agree')}
                            </Trans>
                        </p>
                    </div>
                </div>
                <div className={classes.submit}>
                    <ArrowButton circleRed event={submit} />
                </div>
            </div>
        </form>
    );
};

export default Form;

import React, { useEffect, useState } from "react";
import classes from './Actual.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/CryptoPage/Actual/image.webp';
import line_01 from '../../../assets/img/CryptoPage/Actual/line_01.svg';
import line_02 from '../../../assets/img/CryptoPage/Actual/line_02.svg';
import line_03 from '../../../assets/img/CryptoPage/Actual/line_03.svg';
import line_04 from '../../../assets/img/CryptoPage/Actual/line_04.svg';
import line_mob from '../../../assets/img/CryptoPage/Actual/line_mob.svg';

const list = [
    {
        line: line_01,
        text: 'crypto.actual.li_01',
    },
    {
        line: line_02,
        text: 'crypto.actual.li_02',
    },
    {
        line: line_03,
        text: 'crypto.actual.li_03',
    },
    {
        line: line_04,
        text: 'crypto.actual.li_04',
    },
];

const Actual = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.actual}>
                <h2 className={classes.title}>
                    <Trans>
                        {t('crypto.actual.title')}
                    </Trans>
                </h2>
                <ul className={classes.list}>
                    {list.map((li, index) =>
                        <li key={index} className={classes.item}>
                            <p className={classes.text}>
                                {t(li.text)}
                            </p>
                            {windowWidth > 992 ?
                                <img className={classes.line} src={li.line} alt='' />
                                :
                                <img className={classes.line} src={line_mob} alt='' />
                            }
                        </li>
                    )}
                </ul>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Actual;

import React from "react";
import classes from './Circles.module.scss';
import { Trans, useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/AboutPage/Circles/icon_01.svg';
import icon_02 from '../../../assets/img/AboutPage/Circles/icon_02.svg';
import icon_03 from '../../../assets/img/AboutPage/Circles/icon_03.svg';
import icon_04 from '../../../assets/img/AboutPage/Circles/icon_04.svg';

const Circles = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-100-84-64-30`}>
                    <Trans>
                        {t('about.our_values.title')}
                    </Trans>
                </h2>
                <div className={`${classes.circleWrap} ${classes.circleWrap_01}`}>
                    <div className={classes.circle}>
                        <h4 className={classes.circleContent}>
                            <img className={classes.icon} src={icon_01} alt='' />
                            <span className="font-23-16-14">
                                {t('about.our_values.text_01')}
                            </span>
                        </h4>
                    </div>
                </div>
                <div className={`${classes.circleWrap} ${classes.circleWrap_02}`}>
                    <div className={classes.circle}>
                        <h4 className={classes.circleContent}>
                            <img className={classes.icon} src={icon_02} alt='' />
                            <span className="font-23-16-14">
                                {t('about.our_values.text_02')}
                            </span>
                        </h4>
                    </div>
                </div>
                <div className={`${classes.circleWrap} ${classes.circleWrap_03}`}>
                    <div className={classes.circle}>
                        <h4 className={classes.circleContent}>
                            <img className={classes.icon} src={icon_03} alt='' />
                            <span className="font-23-16-14">
                                {t('about.our_values.text_03')}
                            </span>
                        </h4>
                    </div>
                </div>
                <div className={`${classes.circleWrap} ${classes.circleWrap_04}`}>
                    <div className={classes.circle}>
                        <h4 className={classes.circleContent}>
                            <img className={classes.icon} src={icon_04} alt='' />
                            <span className="font-23-16-14">
                                {t('about.our_values.text_04')}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Circles;

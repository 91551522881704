import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/GlobalComponents/Header/Header';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Banner from '../components/CryptoPage/Banner/Banner';
import Consultations from '../components/CryptoPage/Consultations/Consultations';
import ForThose from '../components/CryptoPage/ForThose/ForThose';
import WhyChoose from '../components/CryptoPage/WhyChoose/WhyChoose';
import Actual from '../components/CryptoPage/Actual/Actual';

const CryptoPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Supra</title>
        <meta name="title" content="" />
        <meta property="og:title" content="" />
        <meta property="twitter:title" content="" />

        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="twitter:description" content="" />
      </Helmet>
      <Header />
      <Banner />
      <Consultations />
      <Actual />
      <ForThose />
      <WhyChoose />
      <Footer />
    </div>
  );
};

export default CryptoPage;

import React from "react";
import classes from './WhatToKnow.module.scss';
import { useTranslation } from "react-i18next";
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";
import item_01 from '../../../assets/img/HomePage/WhatToKnow/item_01.svg';
import item_02 from '../../../assets/img/HomePage/WhatToKnow/item_02.svg';

const WhatToKnow = () => {
    const { t } = useTranslation();
    return (
        <section className={`mt container ${classes.container}`}>
            <div className={classes.whatToKnow}>
                <h2 className={`${classes.title} font-40-800`}>
                    {t('home.what_to_know.title')}
                </h2>
                <h4 className={`${classes.subtitle} font-27-25-16`}>
                    {t('home.what_to_know.subtitle')}
                </h4>
                <p className={`${classes.text} font-17`}>
                    {t('home.what_to_know.text')}
                </p>
                <div className={classes.btn}>
                    <ArrowButton circleRed colorBlack />
                </div>
                <div className={`${classes.item} ${classes.item_01}`}>
                    <img src={item_01} alt='' />
                </div>
                <div className={`${classes.item} ${classes.item_02}`}>
                    <img src={item_02} alt='' />
                </div>
            </div>
        </section>
    );
};

export default WhatToKnow;

import React from "react";
import classes from './Questions.module.scss';
import { useTranslation } from "react-i18next";

const Questions = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.questions}>
                <h2 className={`${classes.title} font-40-800`}>
                    {t('marketing.questions.title')}
                </h2>
                <ul className={`${classes.list} font-17`}>
                    <div className={classes.col}>
                        {Array.from({ length: 10 }).map((_, index) =>
                            <li key={index}>
                                {t(`marketing.questions.li_01_0${index + 1}`)}
                            </li>
                        )}
                    </div>
                    <div className={classes.col}>
                        {Array.from({ length: 10 }).map((_, index) =>
                            <li key={index}>
                                {t(`marketing.questions.li_02_0${index + 1}`)}
                            </li>
                        )}
                    </div>
                </ul>
            </div>
        </section>
    );
};

export default Questions;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/GlobalComponents/Header/Header';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Banner from '../components/AboutPage/Banner/Banner';
import OurMission from '../components/AboutPage/OurMission/OurMission';
import Achievements from '../components/AboutPage/Achievements/Achievements';
import Circles from '../components/AboutPage/Circles/Circles';
import JoinUs from '../components/HomePage/JoinUs/JoinUs';
import Team from '../components/AboutPage/Team/Team';

const AboutPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Supra</title>
        <meta name="title" content="" />
        <meta property="og:title" content="" />
        <meta property="twitter:title" content="" />

        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="twitter:description" content="" />
      </Helmet>
      <Header />
      <Banner />
      <OurMission />
      <Achievements />
      <Circles />
      <Team />
      <JoinUs />
      <Footer />
    </div>
  );
};

export default AboutPage;

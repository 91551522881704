import React from "react";
import classes from './Input.module.scss';

const Input = ({ 
    value, setValue, 
    valid = true,
    type, label,
    placeholder = "",
    required = true,
}) => {
    return (
        <div>
            {/* <div className={`${classes.label} ${required && classes.required} font-16`}>
                {t(label)}
            </div> */}
            <div className={classes.inputField}>
                <input
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className={`${classes.input} ${!valid && classes.error}`}
                />
            </div>
        </div>
    );
};

export default Input;
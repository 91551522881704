import React from "react";
import classes from './Result.module.scss';
import { Trans, useTranslation } from "react-i18next";
import checkbox from '../../../assets/img/AiPage/WeProvide/checkbox.svg';

const Result = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.result}>
                <h2 className={classes.title}>
                    <Trans>
                        {t('it.result.title')}
                    </Trans>
                </h2>
                <ul className={classes.list}>
                    {Array.from({ length: 6 }).map((_, index) =>
                        <li key={index} className={`${classes.item} font-17`}>
                            <img className={classes.checkbox} src={checkbox} alt='' />
                            {t(`it.result.li_0${index + 1}`)}
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default Result;

import React from "react";
import classes from './System.module.scss';
import { Trans, useTranslation } from "react-i18next";
import checkbox from '../../../assets/img/AiPage/WeProvide/checkbox.svg';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";

const System = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.system}>
                <ul className={classes.list}>
                    {Array.from({ length: 6 }).map((_, index) =>
                        <li key={index} className={`${classes.item} font-17`}>
                            <img className={classes.checkbox} src={checkbox} alt='' />
                            {t(`it.system.li_0${index + 1}`)}
                        </li>
                    )}
                </ul>
                <div className={classes.content}>
                    <h2 className={classes.title}>
                        <Trans>
                            {t('it.system.title')}
                        </Trans>
                    </h2>
                    <div className={classes.btn}>
                        <ArrowButton circleRed />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default System;

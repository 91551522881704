import React, { useState, useEffect } from "react";
import classes from './WeProvide.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AiPage/WeProvide/image.webp';
import checkbox from '../../../assets/img/AiPage/WeProvide/checkbox.svg';

const list = [
    'ai.we_provide.text_01',
    'ai.we_provide.text_02',
    'ai.we_provide.text_03',
    'ai.we_provide.text_04',
];

const WeProvide = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.weProvide}>
                <h2 className={`${classes.title} font-30-20`}>
                    {t('ai.we_provide.title')}
                </h2>
                <div className={classes.content}>
                    <ul className={classes.list}>
                        {list.map((li, index) =>
                            <li key={index} className={`${classes.item} font-17`}>
                                <img className={classes.checkbox} src={checkbox} alt='' />
                                {t(li)}
                            </li>
                        )}
                    </ul>
                    {windowWidth > 770 &&
                        <img className={classes.image} src={image} alt='' />
                    }
                </div>
            </div>
        </section>
    );
};

export default WeProvide;

import React from "react";
import classes from './ForThose.module.scss';
import { Trans, useTranslation } from "react-i18next";
import checkbox from '../../../assets/img/AiPage/WeProvide/checkbox.svg';

const ForThose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.forThose}>
                <h2 className={`${classes.title} font-40-30-20`}>
                    <Trans>
                        {t('crypto.for_those.title')}
                    </Trans>
                </h2>
                <ul className={`${classes.list} font-17`}>
                    {Array.from({ length: 3 }).map((_, index) =>
                        <li key={index} className={`${classes.item} font-17`}>
                            <img className={classes.checkbox} src={checkbox} alt='' />
                            {t(`crypto.for_those.li_0${index+1}`)}
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default ForThose;

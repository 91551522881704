import React from "react";
import classes from './Consultations.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Consultations/image_01.avif';
import image_02 from '../../../assets/img/HomePage/Consultations/image_02.avif';
import image_03 from '../../../assets/img/HomePage/Consultations/image_03.avif';
import image_04 from '../../../assets/img/HomePage/Consultations/image_04.avif';

const cards = [
    {
        text: 'AI',
        image: image_01,
    },
    {
        text: 'IT',
        image: image_02,
    },
    {
        text: 'home.consultations.li_03',
        image: image_03,
    },
    {
        text: 'home.consultations.li_04',
        image: image_04,
    },
];

const Consultations = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.consultations}>
                <div className={classes.head}>
                    <h2 className={classes.title}>
                        <span className="font-80-25-20">
                            {t('home.consultations.title_01')}
                        </span>
                        <br />
                        <span className="font-100">
                            {t('home.consultations.title_02')}
                        </span>
                    </h2>
                    <p className={`${classes.text_01} font-17`}>
                        {t('home.consultations.text_01')}
                    </p>
                </div>
                <p className={`${classes.text_02} font-17`}>
                    {t('home.consultations.text_02')}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.cardWrap}>
                            <div className={classes.card}>
                                <img className={classes.cardImage} src={card.image} alt='' />
                                <p className={`${classes.cardText} font-30`}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Consultations;

import React, { useEffect, useState } from "react";
import classes from './WhereNeed.module.scss';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import image_01 from '../../../assets/img/ItPage/WhereNeed/image_01.avif';
import image_02 from '../../../assets/img/ItPage/WhereNeed/image_02.avif';
import image_03 from '../../../assets/img/ItPage/WhereNeed/image_03.avif';
import image_04 from '../../../assets/img/ItPage/WhereNeed/image_04.avif';
import image_05 from '../../../assets/img/ItPage/WhereNeed/image_05.avif';
import image_06 from '../../../assets/img/ItPage/WhereNeed/image_06.avif';

const cards = [
    {
        image: image_01,
        subtitle: 'it.where_need.card_01.subtitle',
        text: 'it.where_need.card_01.text',
    },
    {
        image: image_02,
        subtitle: 'it.where_need.card_02.subtitle',
        text: 'it.where_need.card_02.text',
    },
    {
        image: image_03,
        subtitle: 'it.where_need.card_03.subtitle',
        text: 'it.where_need.card_03.text',
    },
    {
        image: image_04,
        subtitle: 'it.where_need.card_04.subtitle',
        text: 'it.where_need.card_04.text',
    },
    {
        image: image_05,
        subtitle: 'it.where_need.card_05.subtitle',
        text: 'it.where_need.card_05.text',
    },
    {
        image: image_06,
        subtitle: 'it.where_need.card_06.subtitle',
        text: 'it.where_need.card_06.text',
    },
];

const WhereNeed = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState(null);
    const [textSwiper, setTextSwiper] = useState(null);
    const [active, setActive] = useState(0);

    useEffect(() => {
        if (swiper && textSwiper) {
            swiper.slideTo(active);
            textSwiper.slideTo(active);
        }
    }, [active, swiper, textSwiper]);

    const handleSlideChange = (swiper) => {
        setActive(swiper.activeIndex);
    };

    return (
        <section className="mt container">
            <div className={classes.whereNeed}>
                <div className={classes.wrap}>
                    <div className={classes.textSwiperWrap}>
                        <h2 className={`${classes.title} font-30-20`}>
                            {t('it.where_need.title')}
                        </h2>
                        <Swiper
                            className="no-select"
                            grabCursor
                            spaceBetween={35}
                            slidesPerView={1}
                            onInit={(e) => { setTextSwiper(e) }}
                            onSlideChange={handleSlideChange}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index}>
                                    <div className={classes.textCard}>
                                        <h4 className="font-25-16">
                                            {t(card.subtitle)}
                                        </h4>
                                        <p className="font-17">
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    <div className={classes.nav}>
                        <div
                            className={`${classes.navBtn} ${active === 0 ? classes.inactive : ''}`}
                            onClick={() => swiper.slidePrev()}
                        >
                            <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M17.9863 8.62295C18.0428 8.67936 18.0876 8.74634 18.1182 8.82008C18.1487 8.89382 18.1645 8.97286 18.1645 9.05268C18.1645 9.1325 18.1487 9.21154 18.1182 9.28527C18.0876 9.35901 18.0428 9.426 17.9863 9.48241L8.09036 19.3784L33.3489 19.3784C33.5099 19.3784 33.6644 19.4424 33.7783 19.5563C33.8923 19.6702 33.9562 19.8247 33.9562 19.9858C33.9562 20.1469 33.8923 20.3014 33.7783 20.4153C33.6644 20.5292 33.5099 20.5932 33.3489 20.5932L8.09036 20.5932L17.9863 30.4891C18.0428 30.5456 18.0875 30.6126 18.1181 30.6863C18.1486 30.76 18.1643 30.8391 18.1643 30.9189C18.1643 30.9987 18.1486 31.0777 18.1181 31.1514C18.0875 31.2252 18.0428 31.2922 17.9863 31.3486C17.9299 31.405 17.8629 31.4498 17.7892 31.4804C17.7154 31.5109 17.6364 31.5266 17.5566 31.5266C17.4768 31.5266 17.3978 31.5109 17.324 31.4804C17.2503 31.4498 17.1833 31.405 17.1269 31.3486L6.19377 20.4155C6.13729 20.3591 6.09249 20.2921 6.06193 20.2184C6.03136 20.1446 6.01563 20.0656 6.01563 19.9858C6.01563 19.906 6.03136 19.8269 6.06193 19.7532C6.09249 19.6794 6.13729 19.6125 6.19377 19.556L17.1269 8.62295C17.1833 8.56647 17.2503 8.52167 17.324 8.4911C17.3977 8.46054 17.4768 8.4448 17.5566 8.4448C17.6364 8.4448 17.7155 8.46054 17.7892 8.4911C17.8629 8.52167 17.9299 8.56647 17.9863 8.62295Z" fill="#FDECEF" />
                            </svg>
                        </div>
                        <div
                            className={`${classes.navBtn} ${classes.right} ${active === 5 ? classes.inactive : ''}`}
                            onClick={() => swiper.slideNext()}
                        >
                            <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M17.9863 8.62295C18.0428 8.67936 18.0876 8.74634 18.1182 8.82008C18.1487 8.89382 18.1645 8.97286 18.1645 9.05268C18.1645 9.1325 18.1487 9.21154 18.1182 9.28527C18.0876 9.35901 18.0428 9.426 17.9863 9.48241L8.09036 19.3784L33.3489 19.3784C33.5099 19.3784 33.6644 19.4424 33.7783 19.5563C33.8923 19.6702 33.9562 19.8247 33.9562 19.9858C33.9562 20.1469 33.8923 20.3014 33.7783 20.4153C33.6644 20.5292 33.5099 20.5932 33.3489 20.5932L8.09036 20.5932L17.9863 30.4891C18.0428 30.5456 18.0875 30.6126 18.1181 30.6863C18.1486 30.76 18.1643 30.8391 18.1643 30.9189C18.1643 30.9987 18.1486 31.0777 18.1181 31.1514C18.0875 31.2252 18.0428 31.2922 17.9863 31.3486C17.9299 31.405 17.8629 31.4498 17.7892 31.4804C17.7154 31.5109 17.6364 31.5266 17.5566 31.5266C17.4768 31.5266 17.3978 31.5109 17.324 31.4804C17.2503 31.4498 17.1833 31.405 17.1269 31.3486L6.19377 20.4155C6.13729 20.3591 6.09249 20.2921 6.06193 20.2184C6.03136 20.1446 6.01563 20.0656 6.01563 19.9858C6.01563 19.906 6.03136 19.8269 6.06193 19.7532C6.09249 19.6794 6.13729 19.6125 6.19377 19.556L17.1269 8.62295C17.1833 8.56647 17.2503 8.52167 17.324 8.4911C17.3977 8.46054 17.4768 8.4448 17.5566 8.4448C17.6364 8.4448 17.7155 8.46054 17.7892 8.4911C17.8629 8.52167 17.9299 8.56647 17.9863 8.62295Z" fill="#FDECEF" />
                            </svg>
                        </div>
                    </div>
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Navigation]}
                            className="whereNeedSwiper no-select"
                            grabCursor
                            spaceBetween={50}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={handleSlideChange}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className={`whereNeedSwiperSlide ${active === index ? 'whereNeedSwiperSlideActive' : ''}`}>
                                    <div className={classes.card}>
                                        <img className={classes.cardImage} src={card.image} alt='' />
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhereNeed;

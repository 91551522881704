import React, { useState, useEffect } from "react";
import classes from './Interested.module.scss';
import { useTranslation } from "react-i18next";
import circle from '../../../assets/img/HomePage/Interested/circle.webp';
import item_01 from '../../../assets/img/HomePage/Interested/item_01.webp';
import item_02 from '../../../assets/img/HomePage/Circles/item_02.webp';
import item_03 from '../../../assets/img/HomePage/Interested/item_03.svg';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";

const Interested = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.interested}>
                <div className={classes.wrap}>
                    <img className={classes.circle} src={circle} alt='' />
                    <div className={classes.content}>
                        <h2 className={classes.title}>
                            <span className="font-40-800">
                                {t('home.interested.title')}
                            </span>
                            <span className={`${classes.sub_02} font-27-25-16`}>
                                {t('home.interested.subtitle')}
                            </span>
                        </h2>
                        <p className={`${classes.text} font-17`}>
                            {t('home.interested.text')}
                        </p>
                        <div className={classes.btn}>
                            <ArrowButton />
                        </div>
                    </div>
                </div>
                {windowWidth > 992 &&
                    <>
                        <div className={`${classes.item} ${classes.item_01}`}>
                            <img src={item_01} alt='' />
                        </div>
                        <div className={`${classes.item} ${classes.item_02}`}>
                            <img src={item_02} alt='' />
                        </div>
                        <div className={`${classes.item} ${classes.item_03}`}>
                            <img src={item_03} alt='' />
                        </div>
                    </>
                }
            </div>
        </section>
    );
};

export default Interested;

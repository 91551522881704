import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import ArrowButton from '../../GlobalComponents/ArrowButton/ArrowButton';
import image from '../../../assets/img/AiPage/banner.webp';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={classes.title}>
                        <Trans>
                            {t('ai.banner.title')}
                        </Trans>
                    </h1>
                    <h4 className={classes.subtitle}>
                        {t('ai.banner.subtitle')}
                    </h4>
                    <div className={`${classes.text} font-17`}>
                        <p>
                            {t('ai.banner.text_01')}
                        </p>
                        <p>
                            {t('ai.banner.text_02')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <ArrowButton circleRed />
                    </div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import classes from './WeOffer.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/WeOffer/image_01.avif';
import image_02 from '../../../assets/img/HomePage/WeOffer/image_02.avif';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";

const cards = [
    {
        image: image_01,
        title: 'home.we_offer.li_01',
    },
    {
        image: image_02,
        title: 'home.we_offer.li_02',
    },
];

const WeOffer = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.weOffer}>
                <div className={classes.content}>
                    <h2 className={classes.title}>
                        <span className="font-27-25-20">
                            {t('home.we_offer.title')}
                        </span>
                        <span className="font-40-800">
                            {t('home.we_offer.subtitle')}
                        </span>
                    </h2>
                    <div className={`${classes.text} font-17`}>
                        <p>
                            <Trans>
                                {t('home.we_offer.text_01')}
                            </Trans>
                        </p>
                        <p>
                            <Trans>
                                {t('home.we_offer.text_02')}
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <ArrowButton circleRed />
                    </div>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.cardImage} src={card.image} alt='' />
                            <a 
                                className={classes.cardBtn}
                                href={`${process.env.REACT_APP_CABINET_URL}`}
                            >
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 40 41" fill="none">
                                    <path d="M22.0578 32.1122C21.9997 32.0542 21.9536 31.9852 21.9221 31.9094C21.8907 31.8335 21.8745 31.7522 21.8745 31.67C21.8745 31.5879 21.8907 31.5066 21.9221 31.4307C21.9536 31.3548 21.9997 31.2859 22.0578 31.2278L32.2406 21.045L6.25 21.045C6.08424 21.045 5.92526 20.9792 5.80806 20.862C5.69084 20.7448 5.625 20.5858 5.625 20.42C5.625 20.2543 5.69084 20.0953 5.80806 19.9781C5.92526 19.8609 6.08424 19.795 6.25 19.795L32.2406 19.795L22.0578 9.61221C21.9997 9.55414 21.9537 9.48521 21.9223 9.40934C21.8908 9.33346 21.8746 9.25215 21.8746 9.17002C21.8746 9.0879 21.8908 9.00659 21.9223 8.93071C21.9537 8.85484 21.9997 8.78591 22.0578 8.72784C22.1159 8.66977 22.1848 8.62371 22.2607 8.59228C22.3366 8.56085 22.4179 8.54468 22.5 8.54468C22.5821 8.54468 22.6634 8.56085 22.7393 8.59228C22.8152 8.62371 22.8841 8.66977 22.9422 8.72784L34.1922 19.9778C34.2503 20.0359 34.2964 20.1048 34.3278 20.1807C34.3593 20.2566 34.3755 20.3379 34.3755 20.42C34.3755 20.5022 34.3593 20.5835 34.3278 20.6594C34.2964 20.7352 34.2503 20.8042 34.1922 20.8622L22.9422 32.1122C22.8841 32.1703 22.8152 32.2164 22.7393 32.2479C22.6635 32.2793 22.5821 32.2955 22.5 32.2955C22.4179 32.2955 22.3365 32.2793 22.2607 32.2479C22.1848 32.2164 22.1159 32.1703 22.0578 32.1122Z" fill="#FDECEF" />
                                </svg>
                            </a>
                            <h4 className={`${classes.cardTitle} font-18`}>
                                {t(card.title)}
                            </h4>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WeOffer;

import React from "react";
import classes from './Achievements.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/achievments.webp';

const Achievements = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="mt container">
                <div className={classes.achievements}>
                    <div className={classes.content}>
                        <div className={classes.block}>
                            <h4 className="font-25">
                                {t('about.achievements.block_01.title')}
                            </h4>
                            <p className="font-17">
                                {t('about.achievements.block_01.text')}
                            </p>
                        </div>
                        <div className={classes.block}>
                            <h4 className="font-25">
                                {t('about.achievements.block_02.title')}
                            </h4>
                            <p className="font-17">
                                {t('about.achievements.block_02.text')}
                            </p>
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Achievements;

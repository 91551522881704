import React from "react";
import classes from './OurMission.module.scss';
import { useTranslation } from "react-i18next";

const OurMission = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.ourMission}>
                <h2 className="font-100-84-64-30">
                    Наша місія
                </h2>
                <p className={`${classes.text} font-30-26-20-16`}>
                    забезпечити бізнеси та індивідуальних клієнтів високоякісними консультаційними послугами, що сприяють їхньому зростанню та успіху.
                </p>
                <div className={`${classes.row} font-17`}>
                    <p>
                        {t('about.our_mission.text_01')}
                    </p>
                    <p>
                        {t('about.our_mission.text_02')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default OurMission;

import React from "react";
import classes from './Consultations.module.scss';
import { useTranslation } from "react-i18next";

const Consultations = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="container">
                <div className={classes.consultations}>
                    <h2 className={`${classes.title} font-100-84-64-30`}>
                        {t('ai.consultations.title')}
                    </h2>
                    <h4 className={`${classes.subtitle} font-45-30-20`}>
                        {t('ai.consultations.subtitle')}
                    </h4>
                    <p className={`${classes.text} font-17`}>
                        {t('ai.consultations.text')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Consultations;

import React, { useState, useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import head from '../../../assets/img/HomePage/banner_head.webp';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const titleRef = useRef(null);
    const headRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(titleRef.current, {
                x: -150,
            }, {
                x: 0,
                ease: "power4.out",
                duration: 1.5,
                delay: 1,
            });
            gsap.fromTo(headRef.current, {
                x: 150,
            }, {
                x: 0,
                ease: "power4.out",
                duration: 1.5,
                delay: 1,
            });
        }
    }, []);

    return (
        <section className={classes.wrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <div className={classes.head}>
                            <h1 ref={titleRef} className={classes.title}>
                                <span className="font-360">
                                    S
                                </span>
                                <span className="font-165">
                                    upra
                                </span>
                            </h1>
                            <h2 className={classes.subtitle}>
                                <span className="font-43">
                                    {t('home.banner.subtitle_01')}
                                </span>
                                <span className="font-27">
                                    {t('home.banner.subtitle_02')}
                                </span>
                            </h2>
                        </div>
                        {windowWidth > 480 &&
                            <>
                                <p className={`${classes.text_01} font-17`}>
                                    {t('home.banner.text_01')}
                                </p>
                                <p className={`${classes.text_02} font-17`}>
                                    {t('home.banner.text_02')}
                                </p>
                            </>
                        }
                        <div className={classes.btn}>
                            <ArrowButton />
                        </div>
                    </div>
                    <img ref={headRef} className={classes.headImg} src={head} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;

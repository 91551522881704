import React from "react";
import classes from './WhatYouLearn.module.scss';
import { useTranslation } from "react-i18next";

const WhatYouLearn = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.whatYouLearn}>
                <h2 className={`${classes.title} font-30-20`}>
                    {t('ai.what_you_learn.title')}
                </h2>
                <ul className={`${classes.list} font-17`}>
                    {Array.from({ length: 6 }).map((_, index) =>
                        <li key={index}>
                            {t(`ai.what_you_learn.li_0${index+1}`)}
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default WhatYouLearn;

import React, { useState, useEffect, useRef } from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";
import title_svg from '../../../assets/img/HomePage/underbanner_title.svg';
import ArrowButton from "../../GlobalComponents/ArrowButton/ArrowButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const UnderBanner = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const bodyRef = useRef(null);
    const titleRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(titleRef.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "top bottom",
                    end: "bottom bottom",
                    scrub: 3,
                    once: true,
                }
            });
        }
    }, []);

    return (
        <section ref={bodyRef} className={classes.wrap}>
            <div className={classes.titleWrap}>
                <img ref={titleRef} className={classes.title} src={title_svg} alt='Supra' />
                {windowWidth <= 480 &&
                    <>
                        <p className={`${classes.text_01} font-17`}>
                            {t('home.banner.text_01')}
                        </p>
                        <p className={`${classes.text_02} font-17`}>
                            {t('home.banner.text_02')}
                        </p>
                        <div className={classes.btn}>
                            <ArrowButton circleRed />
                        </div>
                    </>
                }
            </div>
        </section>
    );
};

export default UnderBanner;

import React from 'react';
import Header from '../components/GlobalComponents/Header/Header';

const NotFound = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <div className='page__not-found'>
        <span>404</span> Page not found
      </div>
    </div>
  );
};

export default NotFound;

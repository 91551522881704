import React from "react";
import classes from './Quote.module.scss';
import { useTranslation } from "react-i18next";
import item_01 from '../../../assets/img/HomePage/Quote/item_01.svg';
import item_02 from '../../../assets/img/HomePage/Quote/item_02.svg';

const Quote = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.quote}>
                <p className={`${classes.text} font-17`}>
                    {t('home.quote.text')}
                </p>
                <div className={`${classes.item} ${classes.item_01}`}>
                    <img src={item_01} alt='' />
                </div>
                <div className={`${classes.item} ${classes.item_02}`}>
                    <img src={item_02} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Quote;

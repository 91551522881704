import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/GlobalComponents/Header/Header';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import UnderBanner from '../components/HomePage/UnderBanner/UnderBanner';
import Circles from '../components/HomePage/Circles/Circles';
import Consultations from '../components/HomePage/Consultations/Consultations';
import Wish from '../components/HomePage/Wish/Wish';
import WeOffer from '../components/HomePage/WeOffer/WeOffer';
import WhyChoose from '../components/HomePage/WhyChoose/WhyChoose';
import WhatToKnow from '../components/HomePage/WhatToKnow/WhatToKnow';
import Quote from '../components/HomePage/Quote/Quote';
import Interested from '../components/HomePage/Interested/Interested';
import JoinUs from '../components/HomePage/JoinUs/JoinUs';

const HomePage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Supra</title>
        <meta name="title" content="" />
        <meta property="og:title" content="" />
        <meta property="twitter:title" content="" />

        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="twitter:description" content="" />
      </Helmet>
      <Header />
      <Banner />
      <UnderBanner />
      <Circles />
      <Consultations />
      <Wish />
      <WeOffer />
      <WhyChoose />
      <WhatToKnow />
      <Quote />
      <Interested />
      <JoinUs />
      <Footer />
    </div>
  );
};

export default HomePage;

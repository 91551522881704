import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/GlobalComponents/Header/Header';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Banner from '../components/MarketingPage/Banner/Banner';
import Questions from '../components/MarketingPage/Questions/Questions';
import Advantage from '../components/MarketingPage/Advantage/Advantage';

const MarketingPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Supra</title>
        <meta name="title" content="" />
        <meta property="og:title" content="" />
        <meta property="twitter:title" content="" />

        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="twitter:description" content="" />
      </Helmet>
      <Header />
      <Banner />
      <Questions />
      <Advantage />
      <Footer />
    </div>
  );
};

export default MarketingPage;

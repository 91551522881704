import React, { useState, useEffect } from "react";
import classes from './Circles.module.scss';
import { useTranslation } from "react-i18next";
import item_01 from '../../../assets/img/HomePage/Circles/item_01.webp';
import item_02 from '../../../assets/img/HomePage/Circles/item_02.webp';
import item_03 from '../../../assets/img/HomePage/Circles/item_03.webp';
import item_04 from '../../../assets/img/HomePage/Circles/item_04.webp';
import item_05 from '../../../assets/img/HomePage/Circles/item_05.webp';

const Circles = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={classes.title}>
                        <span className={`${classes.ttl_01} font-40`}>
                            {t('home.circles.title')}
                        </span>
                        <span className={`${classes.ttl_02} font-150`}>
                            Supra
                        </span>
                    </h2>
                    <h3 className={`${classes.subtitle} font-23`}>
                        {t('home.circles.subtitle')}
                    </h3>
                </div>
                <div className={`${classes.circleWrap} ${classes.circleWrap_01}`}>
                    <div className={classes.circle}>
                        <h4 className={classes.circleContent}>
                            <span className="font-80">
                                3000+
                            </span>
                            <span className="font-23-16-14">
                                {t('home.circles.li_01')}
                            </span>
                        </h4>
                    </div>
                </div>
                <div className={`${classes.circleWrap} ${classes.circleWrap_02}`}>
                    <div className={classes.circle}>
                        <h4 className={classes.circleContent}>
                            <span className="font-80">
                                50+
                            </span>
                            <span className="font-23-16-14">
                                {t('home.circles.li_02')}
                            </span>
                        </h4>
                    </div>
                </div>
                <div className={`${classes.circleWrap} ${classes.circleWrap_03}`}>
                    <div className={classes.circle}>
                        <h4 className={classes.circleContent}>
                            <span className="font-80">
                                4
                            </span>
                            <span className="font-23-16-14">
                                {t('home.circles.li_03')}
                            </span>
                        </h4>
                    </div>
                </div>
                <div className={`${classes.item} ${classes.item_01}`}>
                    <img src={item_01} alt='' />
                </div>
                <div className={`${classes.item} ${classes.item_02}`}>
                    <img src={item_02} alt='' />
                </div>
                {windowWidth > 1220 &&
                    <>
                        <div className={`${classes.item} ${classes.item_03}`}>
                            <img src={item_03} alt='' />
                        </div>
                        <div className={`${classes.item} ${classes.item_04}`}>
                            <img src={item_04} alt='' />
                        </div>
                    </>
                }
                <div className={`${classes.item} ${classes.item_05}`}>
                    <img src={item_05} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Circles;

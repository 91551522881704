import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/GlobalComponents/Header/Header';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Banner from '../components/AiPage/Banner/Banner';
import Consultations from '../components/AiPage/Consultations/Consultations';
import Structure from '../components/AiPage/Structure/Structure';
import WeProvide from '../components/AiPage/WeProvide/WeProvide';
import Useful from '../components/AiPage/Useful/Useful';
import WhatYouLearn from '../components/AiPage/WhatYouLearn/WhatYouLearn';

const AiPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Supra</title>
        <meta name="title" content="" />
        <meta property="og:title" content="" />
        <meta property="twitter:title" content="" />

        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="twitter:description" content="" />
      </Helmet>
      <Header />
      <Banner />
      <Consultations />
      <Structure />
      <WeProvide />
      <Useful />
      <WhatYouLearn />
      <Footer />
    </div>
  );
};

export default AiPage;
